import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

// Components
import GetAppIcon from '@material-ui/icons/GetApp';

const TermsOfUse = props => {

    return ( 
        <Layout
            title = { 'Terms of Sale' }
        >
            <div className="site-About">
                <div 
                    style = {{
                        width: '100vw',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '5vh'
                    }}
                >
                    <h1>TERMS OF SALE</h1>
                    <br/>
                    <br/>
                    <div
                        style = {{
                            width: '80%'
                        }}
                    >
                        <h3>Forms</h3>
                        <a href = { props.data.datoCmsDocument.creditApplicationFormCanada.url } ><h5><GetAppIcon/>CREDIT APPLICATION FORM (CANADA)</h5></a>
                        <a href = { props.data.datoCmsDocument.creditApplicationFormUsa.url } ><h5><GetAppIcon/>CREDIT APPLICATION FORM (USA)</h5></a>
                        <a href = { props.data.datoCmsDocument.creditCardAuthorizationForm.url } ><h5><GetAppIcon/>CREDIT CARD AUTHORIZATION FORM</h5></a>
                        <br/>
                        <br/>
                        <br/>
                        <h3>PAYMENT / SHIPPING TERMS AND CONDITONS</h3>
                        <p>
                                        1. Minimum online order: $450.00. Checkout is not available if the minimum does not meet.
                            <br/><br/>2. Minimum Order Amount for Free Freight Program is available. Please refer to your Personal Profile once your account is registered and activated.  Free shipping, if entitled, applies to orders to Canada and contiguous US only, excluding Alaska & Hawaii.  
                            <br/><br/>3. All sales claim must be reported in writing within 7 days receipt of goods, or a 20% restocking fee will be applied. 
                            <br/><br/>4. No claim allowed after 14 days receipt of merchandise. No return of merchandise without company consent.
                            <br/><br/>5. All return merchandise must be in original and un-open package with good and sellable condition, free of price tag or sticker. 
                            <br/><br/>6. Items in full display must be returned in full display. 
                            <br/><br/>7. Credit note (or refund, in case of fully paid shipment) will be issued upon approval of claim and/or return of merchandise.  
                            <br/><br/>8. No refund or exchange for closeout and clearance items.
                            <br/><br/>9. All truck shipments are shrink-wrapped, sealed including top of skid.  In case seal is broken, please send digital image of shipment and waybill with remark to accounting@siunsons.com.
                            <br/><br/>10. Company reserves the right for final decision of all claim type.
                            <br/><br/>11. Payment terms are net as follows:<br/>
                                        a) New accounts are C.O.D. credit card or payment in advance.  <br/>
                                        b) Term of payment is available upon completion of credit application O.A.C. Minimum of 3 Trade References , Banking information and signature are required<br/>
                                        c) We accept Cash, Credit Cards (Visa and Mastercard), Wechat, Alipay, Money Orders, Company Cheques, Direct Deposits, Wire Transfer.<br/>
                            <br/><br/>12. Interest at 3% per month (36% per annum) will be charged on all overdue accounts. $20 will be charged on all NSF cheque. 
                            <br/><br/>13. Prices on website are subject to change without prior notice. All merchandise are subject to stock availability.
                            <br/><br/>14. All website specials or promotions are exclusive to online sales only and may only be applied once per store.  Discount cannot be combined . 
                            <br/><br/>15.  Please contact our sales specialist at sales@siunsons.com in case of volume/distributor discount, special/customized order, direct import, FOB ex-factory and/or full container load shipments. Terms & conditions may apply. 
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default TermsOfUse

export const query = graphql`
    query FormQuery{
        datoCmsDocument {
            creditApplicationFormCanada {
                url
            }
            creditApplicationFormUsa {
                url
            }
            creditCardAuthorizationForm {
                url
            }
        }
    }
`
